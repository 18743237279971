
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import PlantaoDuvida from "@/interfaces/PlantaoDuvida";

interface Form {
  texto: string | null;
  url: string;
  turma_id: string | null;
}

const baseForm: Form = {
  texto: "",
  url: "",
  turma_id: "",
};

export default defineComponent({
  components: { FormLoading },
  props: {
    disciplinas: { type: Object, required: true },
    turmas: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, status, afterSubmit } = useForm(
      "plantao-duvidas",
      "plantão ao vivo",
      "o",
      baseForm,
      emit,
      "plantao-duvidas"
    );

    const open = (data: PlantaoDuvida | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            texto: row.value.texto,
            url: row.value.url,
            turma_id: row.value.turma.id,
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      status,
      afterSubmit,
    };
  },
});
