import PlantaoDuvida from "@/interfaces/PlantaoDuvida";

const PlantaoDuvidaColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    style: "width: 100px",
    sortable: false,
  },
  {
    name: "url",
    align: "left",
    label: "URL",
    field: "url",
    sortable: true,
  },
  {
    name: "turma",
    align: "left",
    label: "Turma",
    field: (row: PlantaoDuvida) => row.turma?.nome,
    sortable: true,
  },
  {
    name: "total_alunos",
    align: "left",
    label: "Total de Alunos",
    field: "total_alunos",
    sortable: true,
  },
  {
    name: "status",
    align: "center",
    label: "Status",
    field: "status",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: PlantaoDuvida) => row.created_at,
    sortable: true,
  },
];

export default PlantaoDuvidaColumn;
